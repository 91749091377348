import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { realEstate, solarPanInst, roofing, automain, marketingagency } from "../assets";

const NichesWithStats = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const niches = [
    {
      image: marketingagency,
      name: "Marketing Agencies",
      problems: [
        "Slow lead follow-up results in lost client opportunities",
        "Inefficient hiring processes lead to delays, high costs, and inconsistent candidate selection.",
        "Managing client inquiries and content approvals is inefficient..",
      ],
      solutions: [
        "AI-powered lead nurturing through automated email and SMS follow-ups.",
        "Fully automated hiring system with self-filtering applications and trial task automation for seamless recruitment.",
        "AI agents for handling client inquiries and streamlining content approvals.",
      ],
      link: "https://real-estate-demo-sss.vercel.app/",
    },
    {
      image: realEstate,
      name: "Real Estate",
      problems: [
        "Customer phone sales lines are flooded, leading to missed calls and ignored leads",
        "Current lead generation fails to convert leads into paying customers, or takes hours for a conversion",
        "Fail to capitalize on incoming leads within 5 minutes (lead conversion rates drop by 80%)",
      ],
      solutions: [
        "AI Voice agents that handle inbound calls, convert quickly to paying customer",
        "Lead engagement through email + SMS with curated listings/offers",
        "Instant communication established, with 24/7 website engagement",
      ],
      link: "https://real-estate-demo-sss.vercel.app/",
    },
    {
      image: automain,
      name: "Auto Maintainance/Detailing",
      problems: [
        "Overwhelmed by repetitive customer inquiries about services and pricing, 67% of customers prefer self-service",
        "Difficulty managing appointments and follow-ups during peak seasons, 40% missed or rescheduled",
        "Lack of appointments and lead conversions from website, conversion rate being 2.9%",
      ],
      
      solutions: [
        "Implement AI-powered website agents to handle common inquiries and provide basic quotes",
        "Use an automated scheduling system with reminders to handle appointments",
        "Have AI call center system and website agents to actively convert leads through text, phone, email",
      ],
      link: "https://auto-detail-demo.vercel.app/",
    },
    {
      image: roofing,
      name: "Roofing",
      problems: [
        "Current lead generation fails to convert leads into paying customers",
        "Current leads are not being followed-up with, large loss of potential customers",
        "Incoming service calls/messages can be overwhelming, leading to customer dissatisfaction",
      ],
      solutions: [
        "Lead engagement through email + SMS with curated offers and signup procedure",
        "Automatic lead follow-up process and appointment booking system",
        "Automate service inquiries to ensure quick problem resolution, more positive reviews",
      ],
      link: "https://roofing-demo.vercel.app/",
    },
    {
      image: solarPanInst,
      name: "Solar Panel Installation",
      problems: [
        "Current lead generation fails to convert leads into paying customers",
        "Current leads are not being followed-up with, large loss of potential customers",
        "Incoming service calls/messages can be overwhelming, leading to customer dissatisfaction",
      ],
      solutions: [
        "Lead engagement through email + SMS with curated offers and signup procedure",
        "Automatic lead follow-up process and appointment booking system",
        "Automate service inquiries to ensure quick problem resolution, more positive reviews",
      ],
      link: "https://solar-demo-sss.vercel.app/",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % niches.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + niches.length) % niches.length
    );
  };

  const scrollToGetStarted = () => {
    const getStartedSection = document.getElementById("get-started");
    if (getStartedSection) {
      window.scrollTo({
        top: getStartedSection.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const openLiveDemo = () => {
    window.open(niches[currentIndex].link, "_blank");
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-center items-start w-full h-full sm:pt-64 md:pt-8">
        <div className="relative w-full max-w-[1024px] mx-auto px-[58px] flex items-center">
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full z-10"
            onClick={prevSlide}
          >
            <FaChevronLeft size={20} />
          </button>

          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 overflow-hidden rounded border border-solid border-neutral-border bg-neutral-900 shadow-default">
            <div className="flex h-32 w-full flex-none flex-col items-center relative">
              <div className="flex h-32 w-full flex-none flex-col items-center justify-center overflow-hidden">
                <img
                  className="flex-none w-full h-full object-cover"
                  src={niches[currentIndex].image}
                  alt={niches[currentIndex].name}
                />
              </div>
            </div>

            <div
              className={`flex w-full flex-col items-center gap-6 px-4 py-4 ${
                isMobile ? "" : "sm:px-8 sm:py-8"
              }`}
            >
              <h1
                className={`${
                  isMobile ? "text-2xl" : "text-3xl"
                } font-extrabold bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent`}
              >
                {niches[currentIndex].name}
              </h1>

              <div
                className={`w-full ${
                  isMobile ? "flex flex-col" : "flex flex-row gap-8"
                }`}
              >
                <div
                  className={`flex flex-col items-start justify-start gap-4 ${
                    isMobile ? "w-full" : "w-1/2"
                  }`}
                >
                  <span
                    className={`${
                      isMobile ? "text-xl" : "text-2xl"
                    } font-bold text-red-500`}
                  >
                    Challenges
                  </span>
                  <ul className="list-none p-0 w-full">
                    {niches[currentIndex].problems.map((problem, index) => (
                      <li key={index} className="flex items-start gap-1 mb-2">
                        <img
                          className={`${
                            isMobile ? "h-6 w-6" : "h-8 w-8"
                          } flex-none object-cover mt-1`}
                          src="https://res.cloudinary.com/subframe/image/upload/v1723930140/uploads/3768/z1arx4uqztgooelfrf5q.png"
                          alt="Problem icon"
                        />
                        <span
                          className={`w-full ${
                            isMobile ? "text-xs" : "text-base"
                          } font-semibold text-white pl-1`}
                        >
                          {problem}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                {!isMobile && (
                  <div className="flex w-px flex-none flex-col items-center gap-4 self-stretch bg-gray-500" />
                )}
                <div
                  className={`flex flex-col items-start justify-start gap-4 ${
                    isMobile ? "w-full" : "w-1/2"
                  }`}
                >
                  <span
                    className={`${
                      isMobile ? "text-xl" : "text-2xl"
                    } font-bold text-green-500`}
                  >
                    Solutions
                  </span>
                  <ul className="list-none p-0 w-full">
                    {niches[currentIndex].solutions.map((solution, index) => (
                      <li key={index} className="flex items-start gap-1 mb-2">
                        <img
                          className={`${
                            isMobile ? "h-6 w-6" : "h-8 w-8"
                          } flex-none object-cover mt-1`}
                          src="https://res.cloudinary.com/subframe/image/upload/v1723930133/uploads/3768/hc0hpa6o7uctkumhyeai.png"
                          alt="Solution icon"
                        />
                        <span
                          className={`w-full ${
                            isMobile ? "text-xs" : "text-base"
                          } font-semibold text-white pl-1`}
                        >
                          {solution}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex w-full items-center justify-center gap-2 pt-2">
                <button
                  className={`h-10 w-full bg-gradient-to-r from-yellow-400 to-yellow-500 text-black font-bold px-4 rounded ${
                    isMobile ? "text-sm" : "text-xl"
                  } transition-all duration-300 hover:from-yellow-500 hover:to-yellow-600 hover:text-white hover:shadow-lg`}
                  onClick={openLiveDemo}
                >
                  See Live Demo
                </button>

                <button
                  className={`h-10 w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold px-4 rounded ${
                    isMobile ? "text-sm" : "text-xl"
                  } transition-all duration-300 hover:from-blue-600 hover:to-blue-800 hover:shadow-lg`}
                  onClick={scrollToGetStarted}
                >
                  Book A Meeting!
                </button>
              </div>
            </div>
          </div>

          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full z-10"
            onClick={nextSlide}
          >
            <FaChevronRight size={20} />
          </button>
        </div>
      </div>

      {/* Stats Section */}
      <div className="w-full max-w-[1024px] mt-[109px] px-4 py-6">
        <div className="flex flex-wrap justify-around items-center text-center">
          <div className="w-full sm:w-1/3 mb-4 sm:mb-0 relative">
            <h2 className="text-5xl font-bold text-white">10+</h2>
            <p className="text-xl text-gray-400 mt-2">Happy customers</p>
            {/* Vertical line for desktop */}
            {!isMobile && (
              <div className="hidden sm:block absolute right-0 top-1/2 transform -translate-y-1/2 w-px h-12 bg-gray-500"></div>
            )}
          </div>
          <div className="w-full sm:w-1/3 mb-4 sm:mb-0 relative">
            <h2 className="text-5xl font-bold text-white">100s</h2>
            <p className="text-xl text-gray-400 mt-2">of Solutions</p>
            {/* Vertical line for desktop */}
            {!isMobile && (
              <div className="hidden sm:block absolute right-0 top-1/2 transform -translate-y-1/2 w-px h-12 bg-gray-500"></div>
            )}
          </div>
          <div className="w-full sm:w-1/3">
            <h2 className="text-5xl font-bold text-white">1000s</h2>
            <p className="text-xl text-gray-400 mt-2">of Hours Saved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NichesWithStats;
