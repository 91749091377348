import React, { useState } from 'react';

const ContactSection = () => {
  const [status, setStatus] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') setUsername(value);
    if (name === 'email') setEmail(value);
    if (name === 'message') setMessage(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    const url = 'https://script.google.com/macros/s/AKfycbyn6ASGwJxicjGHj09T4s70iLLS9klegXxqrC1sH9HmWe11jeBZjTKt52QuTRHezKixzg/exec';

    const data = {
      name: username,
      email: email,
      message: message
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=utf-8',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setUsername('');
        setEmail('');
        setMessage('');
        setStatus("Thanks for your submission!");
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus("Oops! There was a problem submitting your form");
    }
  };

  return (
      <section className="w-full py-13">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-6xl font-bold text-white mb-6 text-gradient">Contact Us</h2>
          <p className="text-dimWhite mb-8">Get in touch with us for any inquiries or feedback.</p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-dimWhite mb-2">Name</label>
              <input
                  type="text"
                  id="name"
                  name="name"
                  value={username}
                  onChange={handleChange}
                  required
                  placeholder="Name..."
                  className="w-full px-4 py-3 bg-black-gradient rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-dimWhite mb-2">Email</label>
              <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  required
                  placeholder="Email..."
                  className="w-full px-4 py-3 bg-black-gradient rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-dimWhite mb-2">Message</label>
              <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={handleChange}
                  required
                  rows="4"
                  placeholder="Message..."
                  className="w-full px-4 py-3 bg-black-gradient rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <button
                type="submit"
                className="w-full px-6 py-3 bg-blue-gradient text-black font-bold rounded-md hover:opacity-90 transition duration-300"
            >
              Send Message
            </button>
            {status && <p className="mt-4 text-center text-dimWhite">{status}</p>}
          </form>
        </div>
      </section>
  );
};

export default ContactSection;
