import { useState, useEffect } from "react";
import { close, logo, menu, soloscalel } from "../assets";
import { useNavigate, Link } from "react-router-dom";
import { BookMeeting } from "./Button";

const navLinks = [
  { id: "home", title: "Home" },
  { id: "process", title: "Our Process" },
  { id: "services", title: "Services" },
  { id: "partners", title: "Partners" },
  { id: "testimonials", title: "Testimonials" },
  { id: "case-study", title: "Case Study", external: true, path: "/case-study" },
];

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (nav) => {
    if (nav.external) {
      navigate(nav.path);
    } else {
      const section = document.getElementById(nav.id);
      if (section) {
        const navbarHeight = document.querySelector("nav")?.offsetHeight || 0;
        const targetPosition = section.offsetTop - navbarHeight;

        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      } else {
        window.location.href = `/#${nav.id}`;
      }
    }
    setActive(nav.id);
    setToggle(false);
  };

  return (
    <nav
      className={`w-full fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-primary shadow-lg py-4" : "bg-transparent py-6"
      }`}
    >
      <div className="w-full max-w-7xl mx-auto px-4 flex items-center justify-between">
        {/* Logo - Left */}
        <Link to="/">
          <img src={soloscalel} alt="Logo" className="w-[80px] h-[80px] rounded-full ml-2 sm:ml-6" />
        </Link>

        {/* Desktop Navigation - Center */}
        <ul className="hidden md:flex justify-center items-center flex-1 space-x-8">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`font-poppins cursor-pointer text-[16px] transition-colors duration-300 ${
                active === nav.id ? "text-white" : "text-dimWhite"
              } hover:text-white`}
              onClick={() => handleNavigation(nav)}
            >
              {nav.title}
            </li>
          ))}
        </ul>

        {/* Book Meeting Button - Right */}
        <div className="hidden md:block mr-4 sm:mr-6">
          <BookMeeting />
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden flex items-center justify-end">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-black-gradient absolute top-20 right-4 my-2 min-w-[180px] rounded-xl sidebar z-50`}
          >
            <ul className="flex flex-col w-full items-start space-y-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins cursor-pointer text-[16px] transition-colors duration-300 ${
                    active === nav.id ? "text-white" : "text-dimWhite"
                  } hover:text-white`}
                  onClick={() => handleNavigation(nav)}
                >
                  {nav.title}
                </li>
              ))}
              <li className="mt-4 w-full">
                <BookMeeting />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
