import React, { useState } from "react";

const RoiCalculator = () => {
  const [leadsProcessed, setLeadsProcessed] = useState("");
  const [monthlySpend, setMonthlySpend] = useState("");
  const [projectedIncrease, setProjectedIncrease] = useState("");
  const [avgRevenue, setAvgRevenue] = useState("");
  const [numReps, setNumReps] = useState("");
  const [avgSalary, setAvgSalary] = useState("");
  const [aiCustomerCost, setAiCustomerCost] = useState("");
  const [hoursRepetitive, setHoursRepetitive] = useState("");
  const [repetitiveTimeSavedPercent, setRepetitiveTimeSavedPercent] = useState("");
  const [hourlyWage, setHourlyWage] = useState("");
  const [aiCost, setAiCost] = useState("");
  const [roi, setRoi] = useState(null);
  const [paybackPeriod, setPaybackPeriod] = useState(null);
  const [savingsBreakdown, setSavingsBreakdown] = useState({});

  const calculateROI = () => {
    const leadsProcessedNum = parseFloat(leadsProcessed) || 0;
    const projectedIncreaseNum = parseFloat(projectedIncrease) || 0;
    const avgRevenueNum = parseFloat(avgRevenue) || 0;
    const numRepsNum = parseFloat(numReps) || 0;
    const avgSalaryNum = parseFloat(avgSalary) || 0;
    const aiCustomerCostNum = parseFloat(aiCustomerCost) || 0;
    const hoursRepetitiveNum = parseFloat(hoursRepetitive) || 0;
    const repetitiveTimeSavedPercentNum = parseFloat(repetitiveTimeSavedPercent) || 0;
    const hourlyWageNum = parseFloat(hourlyWage) || 0;
    const aiCostNum = parseFloat(aiCost) || 0;

    const newCustomers = leadsProcessedNum * (projectedIncreaseNum / 100);
    const additionalRevenue = newCustomers * avgRevenueNum;

    const traditionalCost = numRepsNum * avgSalaryNum;
    const savingsFromAI = traditionalCost - aiCustomerCostNum;

    const weeklyHoursSaved = hoursRepetitiveNum * (repetitiveTimeSavedPercentNum / 100);
    const yearlySavings = weeklyHoursSaved * 52 * hourlyWageNum;
    const monthlySavings = yearlySavings / 12;

    if (aiCostNum === 0) {
      setRoi("N/A (AI Cost cannot be zero)");
      setPaybackPeriod("N/A");
      setSavingsBreakdown({});
    } else {
      const totalSavings = additionalRevenue + savingsFromAI + monthlySavings;
      const roiValue = ((totalSavings - aiCostNum) / aiCostNum) * 100;
      const paybackTime = (aiCostNum / totalSavings).toFixed(1);

      setRoi(roiValue.toFixed(2));
      setPaybackPeriod(paybackTime);
      setSavingsBreakdown({
        "Lead Revenue Increase": `$${additionalRevenue.toFixed(2)}`,
        "Customer Service Savings": `$${savingsFromAI.toFixed(2)}`,
        "Monthly Automation Savings": `$${monthlySavings.toFixed(2)}`,
      });
    }
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen bg-black text-white px-6 pt-40">
      <div className="w-full max-w-screen-lg bg-gray-900 shadow-lg rounded-xl p-6 sm:p-10">
        <h2 className="text-3xl font-bold text-center text-white mb-6">AI ROI Calculator</h2>

        {/* Input Fields */}
        <div className="space-y-10">
          <Section title="Lead Generation">
            <Input label="Leads Processed Monthly" value={leadsProcessed} onChange={setLeadsProcessed} />
            <Input label="Monthly Spend on Lead Generation" value={monthlySpend} onChange={setMonthlySpend} />
            <Input label="Projected Increase in Conversion (%)" value={projectedIncrease} onChange={setProjectedIncrease} />
            <Input label="Average Revenue per Customer" value={avgRevenue} onChange={setAvgRevenue} />
          </Section>

          <Section title="Customer Service">
            <Input label="Customer Service Representatives" value={numReps} onChange={setNumReps} />
            <Input label="Average Salary per Representative" value={avgSalary} onChange={setAvgSalary} />
            <Input label="AI-assisted Customer Service Cost" value={aiCustomerCost} onChange={setAiCustomerCost} />
          </Section>

          <Section title="Repetitive Task Automation">
            <Input label="Hours Spent on Repetitive Tasks per Week" value={hoursRepetitive} onChange={setHoursRepetitive} />
            <Input label="Percentage of Time Saved" value={repetitiveTimeSavedPercent} onChange={setRepetitiveTimeSavedPercent} />
            <Input label="Average Hourly Wage" value={hourlyWage} onChange={setHourlyWage} />
          </Section>

          <Section title="AI Investment Cost">
            <Input label="Total AI Implementation & Ongoing Fees" value={aiCost} onChange={setAiCost} />
          </Section>

          {/* Calculate ROI Button */}
          <button 
            onClick={calculateROI}
            className="w-full bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 rounded-lg transition-all duration-300">
            Calculate ROI
          </button>

          {/* Results */}
          {roi && (
            <div className="mt-6 text-center">
              <h3 className="text-2xl font-semibold text-white">Estimated ROI: <span className="text-green-400">{roi}%</span></h3>
              <h3 className="text-xl text-white mt-2">Payback Period: <span className="text-yellow-400">{paybackPeriod} months</span></h3>
            </div>
          )}

          {/* Breakdown */}
          {Object.keys(savingsBreakdown).length > 0 && (
            <div className="mt-8 p-4 bg-gray-800 rounded-lg">
              <h3 className="text-xl font-semibold text-white mb-4 text-center">Savings Breakdown</h3>
              <ul className="space-y-2">
                {Object.entries(savingsBreakdown).map(([key, value]) => (
                  <li key={key} className="flex justify-between text-gray-300">
                    <span>{key}</span>
                    <span className="font-semibold text-green-400">{value}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

// Reusable Components
const Section = ({ title, children }) => (
  <div>
    <h3 className="text-xl font-semibold text-gray-300 mb-4">{title}</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">{children}</div>
  </div>
);

const Input = ({ label, value, onChange }) => (
  <div className="flex flex-col">
    <label className="text-gray-400 text-sm mb-1">{label}</label>
    <input 
      type="number"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter value"
      className="bg-gray-800 text-white p-3 border border-gray-600 rounded-lg 
                 appearance-none 
                 [&::-webkit-inner-spin-button]:appearance-none 
                 [&::-webkit-outer-spin-button]:appearance-none 
                 [-moz-appearance:_textfield]"
    />
  </div>
);

export default RoiCalculator;
