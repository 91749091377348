import Cal, { getCalApi } from "@calcom/embed-react";
  import { useEffect } from "react";
  export default function MyApp() {
	useEffect(()=>{
	  (async function () {
		const cal = await getCalApi({"namespace":"discovery-15"});
		cal("ui", {"hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])
	return( 
       <section className="w-full p-8 h-[1000px] sm:h-[950px] md:h-[700px] lg:h-[700px]">
 <Cal namespace="discovery-15"
	  calLink="soloscale-solutions/discovery-15"
	  style={{width:"100%",height:"100%",overflow:"scroll"}}
	  config={{"layout":"month_view"}}
	/>;
   </section>
  )
  };
  