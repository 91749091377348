import styles from "./style";
import { Analytics } from "@vercel/analytics/react"
import { 
  Arrow, 
  Billing, 
  Business, 
  CardDeal, 
  Niches, 
  CursorEffect, 
  Calendly, 
  CTA, 
  Features,
  Footer, 
  Navbar, 
  Stats, 
  Clients, 
  Testimonials2, 
  ContactSection, 
  Testimonials, 
  Hero, 
  Plans, 
  Help, 
  RoiCalculator,
  SampleServices 
} from "./components";
import { stanford, websize, buildspace, Alovie } from "./assets";
import partners from "./parteners";

import { Routes, Route } from "react-router-dom";
import CaseStudy from "./components/CaseStudy"; // Import Case Study page

const App = () => {
  return (
    <div className="bg-primary w-full overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      {/* Define Routes */}
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <div id="home">
              <div className={`${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                  <Hero />
                </div>
              </div>
              <div className={`${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                  <Features />
                </div>
              </div>
              <div id="home" className={`${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                  <Niches />
                </div>
              </div>
              <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
                <div className={`${styles.boxWidth}`}>
                  <Help />
                  <div id="services" className={`mb-4`}>
                    <SampleServices />
                  </div>
                  <div id="partners" className={"mt-4"}>
                    <Clients partners={partners} />
                  </div>
                  <div id="testimonials">
                    <Testimonials />
                  </div>
                  <div id="process">
                    <Business />
                  </div>
                  <CTA />
                  <div id="get-started">
                    <Calendly />
                  </div>
                  <div id="contact">
                    <ContactSection />
                  </div>
                  <Footer />
                  <Analytics />
                </div>
              </div>
            </div>
            
          }
        />

        {/* Case Study Page */}
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/roi-calculator" element={<RoiCalculator />} />
      </Routes>
    </div>
  );
};

export default App;
